<template>
  <div class="list">
    <Header :now="3" ref="header" :page="'detail'" />
    <div class="content">
      <div class="clumb">
        <div class="btn-back" @click="onBack"><span class="ic"></span>返回</div>
        <div class="clum">
          您的位置：赛事首页-
          <div class="now">作品详情页</div>
        </div>
      </div>
      <div class="part-detail">作品详情</div>
      <div class="more-box">
        <a href="javascript:;" class="more"
          ><span class="text" @click="onJumpForm(1)">我也要参赛</span
          ><span class="ic ic-arrow-right"></span
        ></a>
      </div>
      <div class="detail">
        <div class="detail-box">
          <div class="devise">
            <div class="name">
              设计师：<span>{{ info.author }}</span>
            </div>
          </div>
          <div class="devise-info">
            <div class="role">
              <div class="role-name">
                <div class="role-left">
                  <div class="name">{{ info.role_name }}</div>
                  <div class="blood">
                    <img
                      src="~/assets/images/star_on.png"
                      v-for="(flood, index) in info.blood"
                      :key="index"
                      class="xue"
                    />
                  </div>
                </div>
                <div class="top">
                  <div class="icons">
                    <div class="ic ic-hot" v-if="info.daily_hot > 0"></div>
                    <div class="ic ic-arrow-up" v-if="info.daily_up > 0"></div>
                    <div
                      class="ic ic-arrow-down"
                      v-if="info.daily_down > 0"
                    ></div>
                    <div class="ic ic-fire" v-if="info.daily_share > 0"></div>
                  </div>
                </div>
              </div>
              <div class="skill">
                <div class="skill-item">
                  <div class="skill-name" v-if="info.type !== 4">【{{ info.key1 }}】</div>
                  <div class="skill-cont">{{ info.val1 }}</div>
                </div>
                <div class="skill-item" v-if="info.key2">
                  <div class="skill-name">【{{ info.key2 }}】</div>
                  <div class="skill-cont">{{ info.val2 }}</div>
                </div>
                <div class="skill-item" v-if="info.key3">
                  <div class="skill-name">【{{ info.key3 }}】</div>
                  <div class="skill-cont">{{ info.val3 }}</div>
                </div>
              </div>
              <div class="t-des">【设计心得】</div>
              <div class="desc">{{ info.desc }}</div>
            </div>
          </div>
          <div class="o-info">
            <div class="o-info-box">
              <div class="t-des">上传时间：{{ info.created_at }}</div>
              <div class="bottom">
                <div class="ics">
                  <span
                    class="ic ic-zan"
                    :class="info.vote_status == 1 ? 'ic-zaned' : ''"
                  ></span
                  >{{ info.tickets > 9999 ? "9999+" : info.tickets }}
                </div>
                <div class="ics">
                  <span class="ic ic-pinlun"></span
                  >{{ info.commit > 9999 ? "9999+" : info.commit }}
                </div>
                <div
                  class="ics"
                  @click="onShare"
                  :data-clipboard-text="shareText"
                  id="equSN"
                >
                  <span class="ic ic-fenxiang"></span
                  >{{ info.shares > 99 ? "99+" : info.shares }}
                </div>
              </div>
            </div>
            <div class="ic-btn btn-o" @click="onVote"></div>
            <div class="my-vote" @click="onShowPop()">
              我的票数：{{ vote }}<a href="javascript:;" class="ic ic-add"></a>
            </div>
          </div>
        </div>

        <div class="comment">
          <textarea
            class="textarea"
            v-model="message"
            placeholder="说点什么吧..."
          ></textarea>
          <div class="com-num">{{ message.length }}/250</div>
          <button
            type="button"
            class="btn-submit"
            :class="[
              message.length > 0 ? 'active' : '',
              isSubmit ? 'disabled' : '',
            ]"
            @click="onComment"
            :disabled="isSubmit"
          >
            评 论
          </button>
        </div>

        <div class="pl-list">
          <div class="title">
            <span class="text">全部评论</span
            ><span class="number">（{{ pageTotal }}条）</span>
          </div>
          <div class="pl-item" v-for="(item, index) in list" :key="index">
            <div class="pl-cont">
              <div>
                <span class="author">设计师：{{ item.author }}</span
                ><span class="time">{{ item.created_at.split(" ")[0] }}</span
                ><span class="local">{{ item.location }}</span>
              </div>
              <div class="pl-text">{{ item.message }}</div>
            </div>
            <div class="pl-btns" v-if="item.is_like">
              <div class="zan">
                <a href="javascript:;" class="ic ic-zans active">赞</a
                >{{ item.likes }}
              </div>
              <div class="cai">
                <a href="javascript:;" class="ic ic-cai">踩</a
                >{{ item.dislikes }}
              </div>
            </div>
            <div class="pl-btns" v-else-if="item.is_dislike">
              <div class="zan">
                <a href="javascript:;" class="ic ic-zans">赞</a>{{ item.likes }}
              </div>
              <div class="cai">
                <a href="javascript:;" class="ic ic-cai active">踩</a
                >{{ item.dislikes }}
              </div>
            </div>
            <div class="pl-btns" v-else>
              <div class="zan">
                <a
                  href="javascript:;"
                  class="ic ic-zans"
                  :class="item.is_like ? 'active' : ''"
                  @click="onZan(item.pid)"
                  >赞</a
                >{{ item.likes }}
              </div>
              <div class="cai">
                <a
                  href="javascript:;"
                  class="ic ic-cai"
                  :class="item.is_dislike ? 'active' : ''"
                  @click="onDislike(item.pid)"
                  >踩</a
                >{{ item.dislikes }}
              </div>
            </div>
          </div>
        </div>
        <!-- 由父组件的pageparm单项绑定，更新子数据,callFather是子组件中参数变化触发的 -->
        <Page
          :total="pageTotal"
          :size="pageSize"
          :page="page"
          :changge="pageFn"
        ></Page>
      </div>
    </div>
    <Copyright v-show="!isChannel()" />
    <!-- 我的票数 -->
    <Pop :visible="isShowPopVote" @hidePop="hidePop">
      <div class="pop">
        <div class="ic pop-top">
          我的票数<a
            href="javascript:;"
            class="ic pop-close"
            @click="hidePop"
          ></a>
        </div>
        <div class="pop-cont">
          <h3 class="p-title">【图标含义如下】：</h3>
          <div class="cont-t icons">
            <div class="p-item">
              <span class="ic ic-hot"></span>作品当日获票+评论超过50个
            </div>
            <div class="p-item">
              <span class="ic ic-arrow-up"></span>当日排名上升超过20位
            </div>
            <div class="p-item">
              <span class="ic ic-arrow-down"></span>当日排名下降超过10位
            </div>
            <div class="p-item">
              <span class="ic ic-fire"></span>当日被分享超过10次
            </div>
          </div>
          <h3 class="p-title">【每位玩家初始票数为150票】：</h3>
          <div class="cont-t">
            <p>6月27日投稿&投票开启后，每位玩家可无门槛获得150票</p>
          </div>
          <div class="tips text-left">
            注：该投票计入作品人气热度，但不参与小杀酒社社区有奖活动哦
          </div>
        </div>
      </div>
    </Pop>
    <!-- 活动未开始弹框 -->
    <Pop-tips :visible="isShowPopTips" @hidePop="hidePop" />
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "../components/content/header.vue";
import Login from "../components/content/login.vue";
import Copyright from "@/components/content/Copyright";
import Page from "@/components/common/Page.vue";
import Pop from "../components/content/pop.vue";
import Clipboard from "clipboard";
import { judgeWeb ,isChannel} from "@/common/util";

import {
  reqWorkDetail,
  reqVote,
  reqMyVote,
  reqCommentSave,
  reqComment,
  reqCommentLike,
  reqCommentDisLike,
  reqShare,
} from "@/api/http";

import PopTips from "@/components/content/PopTips";
import { config } from "@/common/const";

export default {
  name: "Work",
  components: {
    Header,
    Login,
    Page,
    Copyright,
    Pop,
    PopTips,
  },
  computed: {},
  data() {
    return {
      agent: "pc", //是手机访问还是电脑
      token: "",
      info: "",
      vote: 0, //我的票数
      message: "",
      page: 1, //当前页码
      pageSize: 8, //每页显示条目个数不传默认10
      pageTotal: 0, //总数
      list: [],
      isShowPopVote: false,

      isGoSubmit: false, //是否可投稿
      isShowPopTips: false, //开始投稿日期提示弹框
      timer: null, //定时器
      shareText: config.shareMyInfo, //分享文案
      config,isChannel,
      isSubmit: false, //是否提交了评论
    };
  },
  mounted() {
    //判断pc还是h5
    this.agent = 'pc';
    this.token = localStorage.getItem("token");
    if (this.token) {
      this.loadMyVote();
    }
    this.loadWorkDetail();
    //定时开启开赛日
    this.timer = setInterval(this.funRtime, 1000);
  },
  methods: {
    //倒计时活动开始日期
    funRtime() {
      var startTime = new Date(config.startTime);
      var NowTime = new Date();
      if (NowTime >= startTime) {
        //活动开始了
        console.log("开始了detail");
        this.isGoSubmit = true;
        clearInterval(this.timer);
      } else {
        console.log("没开始detail");
        this.isGoSubmit = false;
      }
    },
    // 作品列表
    loadWorkDetail() {
      let wid = this.$route.query.wid;
      reqWorkDetail({ wid })
        .then((res) => {
          if (res.status === 0) {
            this.info = res.data;
          } else if (res.status == -1) {
            this.token = "";
            localStorage.clear();
            this.$refs.header.showLogin();
          } else {
            this.$toast.show(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.show("出错了");
        });
    },
    //返回选角色
    onBack() {
      history.back();
    },
    //投票
    onVote() {
      let wid = this.$route.query.wid;
      let { token } = this;
      reqVote({ wid, token })
        .then((res) => {
          if (res.status === 0) {
            this.$toast.show("投票成功");
            if (this.vote > 0) {
              this.vote -= 1;
            } else {
              this.vote = 0;
            }

            this.info.tickets += 1;
          } else if (res.status == -1) {
            this.token = "";
            localStorage.clear();
            this.$refs.header.showLogin();
          } else {
            this.$toast.show(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.show("出错了");
        });
    },
    //我的投票
    loadMyVote() {
      let { token } = this;
      reqMyVote({ token })
        .then((res) => {
          if (res.status === 0) {
            this.vote = res.data.vote;
          } else if (res.status == -1) {
            this.token = "";
            localStorage.clear();
            this.$refs.header.showLogin();
          } else {
            this.$toast.show(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.show("出错了");
        });
    },
    //评论
    onComment() {
      let { token, message } = this;
      let wid = this.$route.query.wid;
      if (!message) {
        this.$toast.show("请输入内容");
        return;
      }
      if (message.length > 250) {
        this.$toast.show("评论内容超出了限制");
        return;
      }
      this.isSubmit = true;
      reqCommentSave({ token, wid, message })
        .then((res) => {
          if (res.status === 0) {
            this.$toast.show("发表成功");
            this.loadCommentList();
            this.message = "";
            this.info.commit += 1;
            setTimeout(() => {
              this.isSubmit = false;
            }, 2000);
          } else if (res.status == -1) {
            this.token = "";
            localStorage.clear();
            this.$refs.header.showLogin();
            this.isSubmit = false;
          } else {
            this.$toast.show(res.msg);
            this.isSubmit = false;
          }
        })
        .catch((err) => {
          this.isSubmit = false;
          this.$toast.show("出错了");
        });
    },
    // 评论列表
    loadCommentList() {
      let wid = this.$route.query.wid;
      let { page, pageSize, token } = this;
      reqComment({ page, page_size: pageSize, wid, token })
        .then((res) => {
          if (res.status === 0) {
            if (res.data.list) {
              let list = res.data.list;
              this.list = list;
            } else {
              this.list = [];
            }
            this.pageTotal = res.data.total_num;
          } else if (res.status == -1) {
            this.token = "";
            localStorage.clear();
            this.$refs.header.showLogin();
          } else {
            this.$toast.show(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.show("出错了");
        });
    },
    //页码切换执行方法
    pageFn(val) {
      this.page = val;
      this.token = localStorage.getItem("token");
      this.loadCommentList();
    },
    //赞
    onZan(pid) {
      let { token, list } = this;
      reqCommentLike({ token, pid })
        .then((res) => {
          if (res.status === 0) {
            for (let i = 0; i < list.length; i++) {
              if (list[i].pid == pid) {
                list[i].likes += 1;
                list[i].is_like = true;
              }
            }
            this.list = list;
          } else if (res.status == -1) {
            this.token = "";
            localStorage.clear();
            this.$refs.header.showLogin();
          } else {
            this.$toast.show(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.show("出错了");
        });
    },
    //踩
    onDislike(pid) {
      let { token, list } = this;
      reqCommentDisLike({ token, pid })
        .then((res) => {
          if (res.status === 0) {
            for (let i = 0; i < list.length; i++) {
              if (list[i].pid == pid) {
                list[i].dislikes += 1;
                list[i].is_dislike = true;
              }
            }
            this.list = list;
          } else if (res.status == -1) {
            this.token = "";
            localStorage.clear();
            this.$refs.header.showLogin();
          } else {
            this.$toast.show(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.show("出错了");
        });
    },
    //跳转投稿
    onJumpForm(role) {
      if (this.isGoSubmit) {
        if (this.token) {
          localStorage.removeItem("workdetail");
          this.$router.push({ path: "/form", query: { role } });
        } else {
          this.$refs.header.showLogin();
        }
      } else {
        this.isShowPopTips = true;
      }
    },
    //显示弹框
    onShowPop() {
      this.isShowPopVote = true;
    },
    hidePop() {
      this.isShowPopVote = false;
      this.isShowPopTips = false;
    },
    //分享
    onShare() {
      let wid = this.$route.query.wid;
      if (isChannel()) {
        this.shareText = config.channelShareMyInfo + "#/detail?wid=" + wid;
      } else {
        this.shareText = config.shareMyInfo + "#/detail?wid=" + wid;
      }

      let clipboard = new Clipboard("#equSN");
      clipboard.on("success", (e) => {
        // 释放内存
        clipboard.destroy();
      });

      clipboard.on("error", (e) => {
        this.$toast.show("该浏览器不支持复制");
        clipboard.destroy();
      });
      let { token } = this;
      reqShare({ token, wid })
        .then((res) => {
          if (res.status == 0) {
            this.info.shares += 1;
            this.$toast.show("已复制到粘贴板，快去分享吧！");
          } else if (res.status == -1) {
            localStorage.clear();
            this.token = "";
            this.$refs.header.showLogin();
          } else {
            this.$toast.show(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.show("网络出错了");
        });
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="less" scoped>
.pop-top {
  background-position: 0 0;
  border-right: 0.02rem solid #a38b5c;
  border-left: 0.02rem solid #a38b5c;
  background: url(../assets/images/popu-header.png) no-repeat;
  background-size: cover;
  .pop-close {
    left: calc(50% - 0.21rem);
    bottom: -5.2rem;
  }
}
.pop-cont {
  background-color: #faf8f0;
  .tips {
    color: #6e5a46;
  }
}
.icons {
  .ic {
    width: 0.24rem;
    height: 0.24rem;
    margin: 0 0.03rem;
    display: block;

    background-size: 8rem auto;
    font-size: 0;
  }

  .ic-hot {
    width: 0.16rem;
    background: url(../assets/images/hot.png) no-repeat;
  }

  .ic-arrow-up {
    background: url(../assets/images/arrow-up.png) no-repeat;
  }

  .ic-arrow-down {
    background: url(../assets/images/arrow-down.png) no-repeat;
  }

  .ic-fire {
    background: url(../assets/images/fire.png) no-repeat;
  }
}
.ics {
  display: flex;
  margin-right: 0.35rem;

  .ic-zan {
    width: 0.26rem !important;
    height: 0.22rem;
    background: url(../assets/images/white-heart.png) no-repeat;
    background-position: 0 0 !important;
    &:hover {
      background: url(../assets/images/sprites.png) no-repeat;
      background-position: -5.47rem -0.64rem !important;
    }
  }
  .ic-pinlun {
    width: 0.26rem !important;
    height: 0.22rem;
    background: url(../assets/images/white-comments.png) no-repeat;
    background-position: 0 0 !important;
    &:hover {
      background: url(../assets/images/sprites.png) no-repeat;
      background-position: -5.86rem -0.64rem !important;
    }
  }
  .ic-fenxiang {
    width: 0.26rem !important;
    // height: 0.22rem;
    background: url(../assets/images/white-share.png) no-repeat;
    background-position: 0 0 !important;
    &:hover {
      background: url(../assets/images/sprites.png) no-repeat;
      background-position: -6.22rem -0.64rem !important;
    }
  }
}

.detail {
  background: url(../assets/images/detail-bg.png) no-repeat;
  background-size: 100% 100%;
  .detail-box {
    padding-top: 0.68rem;
    display: flex;
    flex-direction: column;
    .devise {
      height: 0.4rem;
      font-family: MicrosoftYaHei-Bold;
      font-size: 0.2rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 0.4rem;
      color: #ffffff;
      text-align: left;
      border-bottom: 0.02rem solid #8b9ca0;
      span {
        font-family: MicrosoftYaHei;
        font-size: 0.18rem;
        font-weight: normal;
        font-stretch: normal;
        color: #ffffff;
      }
    }
    .devise-info {
      margin-top: 0.4rem;
      .role {
        display: flex;
        flex-direction: column;
        .role-name {
          display: flex;
          justify-content: space-between;
          .role-left {
            display: flex;
            align-items: center;
            .name {
              font-family: MicrosoftYaHei-Bold;
              font-size: 0.24rem;
              font-weight: normal;
              font-stretch: normal;

              letter-spacing: 0.02rem;
              color: #ffffff;
            }
            .blood {
              display: flex;
              margin-left: 0.2rem;
              img {
                margin-right: 0.08rem;
              }
            }
          }
          .top {
            display: flex;
            align-items: center;
            .icons {
              display: flex;
            }
          }
        }
        .skill {
          margin-top: 0.3rem;
          .skill-item {
            display: flex;
            margin-bottom: 0.3rem;
            .skill-name {
              font-family: MicrosoftYaHei-Bold;
              font-size: 0.24rem;
              font-weight: normal;
              font-stretch: normal;
              line-height: 0.4rem;
              letter-spacing: 0.08rem;
              color: #ffffff;
            }
            .skill-cont {
              width: 9.2rem;
              font-family: MicrosoftYaHei;
              font-size: 0.18rem;
              font-weight: normal;
              font-stretch: normal;
              line-height: 0.28rem;
              text-align: left;
              letter-spacing: 0px;
              color: #ffffff;
            }
          }
        }
        .t-des {
          font-family: MicrosoftYaHei-Bold;
          font-size: 0.24rem;
          font-weight: normal;
          font-stretch: normal;
          line-height: 0.4rem;
          letter-spacing: 0.08rem;
          text-align: left;
          color: #ffffff;
        }
        .desc {
          width: 10.21rem;
          font-family: MicrosoftYaHei;
          font-size: 0.18rem;
          font-weight: normal;
          font-stretch: normal;
          line-height: 0.28rem;
          text-align: left;
          letter-spacing: 0px;
          color: #ffffff;
          padding-left: 0.1rem;
          margin-bottom: 0.4rem;
        }
      }
    }
    .o-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 0.3rem;
      padding-left: 0;
      border-top: 0.02rem solid #8b9ca0;
      .o-info-box {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      .bottom {
        display: flex;
      }
      .btn-o {
        height: 0.99rem;
        margin: 0.45rem auto 0 auto;
        background: url(../assets/images/lapiao.png) no-repeat;
      }
      .my-vote {
        margin-left: 0;

        font-family: MicrosoftYaHei;
        font-size: 0.18rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.23rem;
        letter-spacing: 0.02rem;
        color: #a5b5b8;
      }
    }
  }
}
.pl-list {
  .zan {
    .ic-zans {
      width: 0.26rem;
      height: 0.26rem;
      margin-right: 0.15rem;
      background: url(../assets/images/dianzan.png) no-repeat;
      background-size: cover;
      background-position: 0 0;
      &:hover {
        width: 0.26rem;
        height: 0.26rem;
        margin-right: 0.15rem;
        background: url(../assets/images/sprites.png) no-repeat;
        background-size: 8rem auto;
        background-position: -4.16rem 0;
      }
    }
    .active {
      width: 0.26rem;
      height: 0.26rem;
      margin-right: 0.15rem;
      background: url(../assets/images/sprites.png) no-repeat;
      background-size: 8rem auto;
      background-position: -4.16rem 0;
    }
  }
  .cai {
    .ic-cai.active {
      width: 0.26rem;
      height: 0.26rem;
      margin-right: 0.15rem;
      background: url(../assets/images/sprites.png) no-repeat;
      background-size: 8rem auto;
      background-position: -4.16rem -0.36rem;
    }
    .ic-cai {
      width: 0.26rem;
      height: 0.26rem;
      margin-right: 0.15rem;
      background: url(../assets/images/cai.png) no-repeat;
      background-size: cover;
      background-position: 0 0;
      &:hover {
        width: 0.26rem;
        height: 0.26rem;
        margin-right: 0.15rem;
        background: url(../assets/images/sprites.png) no-repeat;
        background-size: 8rem auto;
        background-position: -4.16rem -0.36rem;
      }
    }
  }
}
</style>
